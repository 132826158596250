import { registerApplication, start } from 'single-spa'

const singleSpaIntegrations = [{ name: '@cx/entitlements-dashboard', path: '/entitlements' }]

for (const { name, path } of singleSpaIntegrations) {
  registerApplication({
    name,
    app: () => withLoader(name),
    activeWhen: (location) => location.pathname.startsWith(path),
  })
}

registerApplication({
  name: '@cx/platform-console',
  app: () => withLoader('@cx/platform-console'),
  activeWhen: (location) =>
    !singleSpaIntegrations.some(({ path }) => location.pathname.startsWith(path)),
})

async function withLoader(name) {
  placeLoader()
  const app = await System.import(name)
  removeLoader()
  return app
}

function placeLoader() {
  document.body.appendChild(
    Object.assign(document.createElement('div'), {
      role: 'progressbar',
      tabindex: '0',
      class: 'eds-spinner eds-spinner--large',
      id: 'single-spa-loader',
    })
  )
}

function removeLoader() {
  document.getElementById('single-spa-loader').remove()
}

start({
  urlRerouteOnly: true,
})
